<template>
  <main class="kb-main" id="kb-myclass">
    <lxp-main-header :show-title="false"  :show-custom-title="true" :title="menuTitle" :show-breadcrumb="true"/>

    <div class="main-content main-component">
      <!-- calendar -->
      <TrainWeekCalendar
          :is-loading="isLoading"
          :day-func="dayFunc"
      />

      <TrainSchedule
          v-if="isProceeding"
          :items="schedules"
      />

      <TrainCourses
          :desc-title="descTitle"
          :train-type="trainType"
          :title="menuTitle"
          :items="items"
          :is-scheduled="isScheduled"
          :is-loading="isLoading"
          :learns="learns"
          :exItems="exItems"
      />
    </div>
  </main>
</template>

<script>

import TrainWeekCalendar from '@/components/train/TrainWeekCalendar';
import TrainSchedule from '@/components/train/TrainSchedule';
import TrainCourses from '@/components/train/TrainCourses';
import LxpMainHeader from '@/components/common/LxpMainHeader';
import {trainMainSetup} from '@/assets/js/modules/train/train-setup';

export default {
  name: 'TrainProceeding',
  components: {LxpMainHeader, TrainCourses, TrainSchedule, TrainWeekCalendar},
  setup: trainMainSetup
}
</script>
