<template>
  <article class="course-card">
    <div class="course-image">
      <button class="image-link" @click="goLearn(item, session.itn)" />
      <figure class="image-figure">
        <img v-if="item.thumb" :src="getThumbUrl(item.thumb)" alt="">
        <img v-else-if="item.mstThumb" :src="getThumbUrl(item.mstThumb)" alt="">
        <CourseThumb v-else
                     :num="item.crseMstSn"
        />
      </figure>
    </div>
    <div class="course-content">
      <div class="content-header">
        <div v-if="isScheduled && item.dday" class="d-day"><span class="d-day-text">D-{{ item.dday }}</span></div>
        <h5 class="title">
          <a href="javascript:" class="title-link" @click="goLearn(item, session.itn)">{{item.crseNm}}</a>
          <!--          <router-link :to="{name: 'LearnMain', params:{distCrseSn:item.distCrseSn, view: 'home'}}" class="title-link">{{item.crseNm}}</router-link>-->
        </h5>
      </div>
      <div class="content-meta">
        <span class="text">{{item.prvdrNm}}</span>
        <span class="text-extra">{{manipulate(item, exItems, learns)}}</span>
        <span class="text">{{timestampToDateFormat(item.bgngDt, 'yyyy.MM.dd')}} - {{timestampToDateFormat(item.endDt, 'yyyy.MM.dd')}}</span>
      </div>
      <div v-if="item.crseMlg > 0" class="content-mileage">
        <span class="text">마일리지 {{item.crseMlg}}</span>
      </div>
    </div>
<!--    <div class="course-icons">-->
<!--      <div class="icons">-->
<!--        <i class="icon-course-collective" title="집합연수"></i>-->
<!--        <i class="icon-course-cyber" title="사이버"></i>-->
<!--        <i class="icon-course-book" title="교재"></i>-->
<!--      </div>-->
<!--    </div>-->
    <div v-if="isScheduled" class="course-actions">
      <button class="kb-btn kb-btn-outline rounded-lg" type="button" @click="toggleApplyDetail(item)"><span class="text">수강신청정보</span></button>
    </div>
  </article>
</template>

<script>
import {trainCourseElSetup} from '@/assets/js/modules/train/train-setup';
import CourseThumb from '@/components/apply/CourseThumb';

export default {
  name: 'TrainRow',
  components: {CourseThumb},
  props: {
    item: Object,
    isScheduled: Boolean,
    learns: Array,
    exItems: Array
  },
  emits: ['showDetail'],
  setup: trainCourseElSetup
}
</script>

<style scoped>

.text-extra {
  font: normal 16px/20px var(--kb-font-KBFGDisplayB);
  color: var(--kb-primary) !important;
  padding-left: 10px;
}

</style>
