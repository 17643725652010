<template>
  <section class="content-section section-schedule">
    <header class="section-header">
      <h4 class="title">일정</h4>
    </header>
    <!-- schedule-list-wrap -->
    <div class="striped-list-wrap schedule-list-wrap">
      <ul class="striped-list schedule-list">
        <li v-if="exposureItems.length === 0" class="striped-row schedule-item">
          <div class="striped-column schedule-title is-deactivate">
            <strong class="text">오늘 등록된 일정이 없어요.</strong>
          </div>
        </li>
        <li v-else v-for="(item, idx) in exposureItems" class="striped-row schedule-item" :key="idx">
          <div class="striped-column schedule-date">
            <strong class="text">{{ timestampToDateFormat(item.objBgngDt, 'hh:mm') }} ~ {{ timestampToDateFormat(item.objEndDt, 'hh:mm') }}</strong>
          </div>
          <div class="striped-column schedule-title">

            <router-link
                v-if="currentYmd === item.objBgngYmd"
                :to="{name: 'LearnMain', params:{ distCrseSn: item.distCrseSn, view: 'index' }, query: {learn: item.lrnObjDtlDistSn}}" class="image-link"><strong class="text">{{ item.objNm }}</strong></router-link>
            <router-link
                v-else
                :to="{name: 'LearnMain', params:{ distCrseSn: item.distCrseSn, view: 'home' }}" class="image-link"><strong class="text">{{ item.objNm }}</strong></router-link>
          </div>
        </li>
      </ul>
    </div>
    <!-- //schedule-list-wrap -->
  </section>
</template>

<script>

import {trainScheduleSetup} from '@/assets/js/modules/train/train-setup';

export default {
  name: 'TrainSchedule',
  props: {
    items: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  setup: trainScheduleSetup
}
</script>
