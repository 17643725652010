<template>
  <section class="content-section">
    <header class="section-header mb-3">
      <h4 class="title">{{ title }}</h4>
      <div class="header-sub">
        <div class="listbox">
          <div v-if="items.length > 0" class="list-total">
            <span class="text">총 <em class="text-count">{{ items.length }}</em>건</span>
          </div>
          <div class="list-options">
            <!-- [!dev] is-active 활성화 클래스 -->
            <a href="javascript:" class="view-option" :class="{'is-active': mode === 'col'}" @click="selectMode('col')">
              <i class="icon-view-col"></i>
            </a>
            <a href="javascript:" class="view-option" :class="{'is-active': mode === 'row'}" @click="selectMode('row')">
              <i class="icon-view-row"></i>
            </a>
          </div>
        </div>
      </div>
    </header>

    <div v-if="$route.path.indexOf('proceeding') > 0"  class="list-top pb-5">
      <div class="top-column">
        <!-- list-top > list-sort -->
        <div class="list-sort">
          <div class="sort">
            <SortButtonSelect
                v-model="filterDutyEduYn"
                v-model:toggle="toggle"
                title="전체"
                :options="[{display: '의무교육과정', dutyEduYn: 'Y'}]"
                sequence-key="dutyEduYn"
                name-key="display"
                :is-all="true"
                :width="200"
                @selected="filterSelect"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="course-list-container" :data-view="mode">
      <!-- course-column OR course-row -->
      <div v-for="(item, idx) in items" :class="courseClass" :key="idx">
        <TrainCol v-if="mode === 'col'"
                  :item="item"
                  :is-scheduled="isScheduled"
                  @showDetail="showApplyDetail"
                  :learns="learns"
                  :exItems="exItems"
        />
        <TrainRow v-else-if="mode === 'row'"
                  :item="item"
                  :is-scheduled="isScheduled"
                  @showDetail="showApplyDetail"
                  :learns="learns"
                  :exItems="exItems"
        />
      </div>
      <!-- course-column -->
    </div>

    <div v-if="items.length === 0 && !isLoading" class="search-container">
      <div class="result-empty">
        <img src="../../assets/lxp/images/common/img_empty.png" alt="">
        <p class="text">{{descTitle}} 연수가 없습니다.</p>
      </div>
    </div>

    <ApplyTrainModal
        v-if="applyModal"
        v-model="applyModal"
        :params="targetParams"
        :is-view="true"
    />
  </section>
</template>

<script>

import TrainCol from '@/components/train/TrainCol';
import TrainRow from '@/components/train/TrainRow';
import ApplyTrainModal from '@/components/apply/ApplyTrainModal';
import SortButtonSelect from '@/components/common/SortButtonSelect';
import {trainCoursesSetup} from '@/assets/js/modules/train/train-setup';

export default {
  name: 'TrainCourses',
  components: {SortButtonSelect, ApplyTrainModal, TrainRow, TrainCol},
  props: {
    title: String,
    descTitle: String,
    trainType: String,
    items: Array,
    isScheduled: Boolean,
    isLoading: Boolean,
    learns: Array,
    exItems: Array
  },
  setup: trainCoursesSetup

}
</script>
